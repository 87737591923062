import * as React from 'react';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Chip, Container, Divider, Snackbar } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ShareDialog(props) {

  const {c_color, open, onClose } = props;

  // Snackbar 상태 추가
  const [snackState, setSnackState] = React.useState({
    open: false,
    vertical: 'center',
    horizontal: 'center'
  });
  const [snackMessage, setSnackMessage] = React.useState("");
  
  const handleSnackClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  //클립보드에 주소를 복사하기
  const copyPath=()=>{
    const currentUrl = window.location.href; // 현재 URL 가져오기
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setSnackMessage("주소가 클립보드에 복사되었습니다!");
        setSnackState({ ...snackState, open: true });
      })
      .catch((error) => {
        console.error("클립보드 복사 실패:", error);
      });
  }

  return (
    <React.Fragment>
        <Snackbar
          open={snackState.open}
          vertical={snackState.vertical}
          horizontal={snackState.horizontal}
          onClose={handleSnackClose}
          message={snackMessage} // 메시지를 'message' prop으로 전달
          autoHideDuration={1500} // 2초 후 자동으로 닫히도록 설정
          sx={{ 
            position: 'fixed',
            '& .MuiSnackbarContent-root': {
                minWidth: '200px',
                justifyContent: 'center',
                backgroundColor:alpha(c_color,0.5),
            }
         }}
      />
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 1, textAlign:'center', backgroundColor:alpha(c_color,0.3), color:'#333',fontSize:'15px' }} id="customized-dialog-title">
          당신의 챗봇을 알려주세요!
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent dividers>
            <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'center'}}>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                  아래 주소를 클릭하면
                  해당 챗봇의 주소를 복사합니다!
                </Typography>
            </Container>
            
            <Divider sx={{maxWidth:'80%', margin:'10px auto'}}/>

            <Chip     size='large' color="primary" variant="outlined"
                      sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'center' }}  
                      label = {window.location.href}
                      onClick={copyPath}>
                      
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                {/* <Typography variant='span' sx={{ fontSize:'13px',margin:'' }}>
                  <span style={{fontWeight:'bold', color:'#6574CD'}}>
                    {window.location.href}
                  </span>
                </Typography> */}
            </Chip>

        </DialogContent>
        <DialogActions>
        <Button
              color="dark"
              size="small"
              onClick={onClose}
              sx={{ textTransform: 'none' }}
          >
              닫기
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}