import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect } from 'react';
import { alpha, createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { CircularProgress, Container, Paper, Typography } from '@mui/material';

//컴포넌트 import
import BottomBar from './components/bottomMenu';
import Header from './include/Header';
import ChatScreen from './components/ChatScreen';
import Noticebar from './components/Noticebar';
import LoadingBackdrop from './backdrops/LoadingBackdrop';
import CloseBackdrop from './backdrops/CloseBackdrop';

//Redux 관련 import
import { useSelector,useDispatch  } from 'react-redux';
import { setCl_key, setCs_questions, setKeywords, setNotices, setChatbot, setC_color, setIsActive,setIsMsging, setIsLoaded, setIsClosed, setChatbubbles } from './store/store';
import moment from 'moment';


const theme = createTheme({
  typography: {
    fontFamily: 'Pretendard',
    //fontFamily: 'GmarketSansLight, Arial, sans-serif',
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          // maxWidth: '520px',  // 앱의 최대 가로 길이를 520px로 제한
          margin: '0 auto',   // 화면 중앙 배치
        },
      },
    },
  }
});

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const c_key = urlParams.get('c_key');

  // Redux 상태 가져오기
  const chatState = useSelector((state) => state.chatState);

  const { apiUrl, chatbot,c_color,isActive,isMsging,isLoaded,isClosed } = chatState;

  //상태 변환 함수 만들기
  const dispatch = useDispatch();

  const updateClKey = (cl_key) => {
    dispatch(setCl_key(cl_key));
  }

  const updateCsQuestions = (cs_questions) => {
    dispatch(setCs_questions(cs_questions));
  }

  const updateKeywords = (keywords) => {
    dispatch(setKeywords(keywords));
  }  

  const updateNotices = (notices) => {
    dispatch(setNotices(notices));
  }  

  const updateChatbot = (chatbot) => {
    dispatch(setChatbot(chatbot));
  }  

  const updateC_color = (c_color) => {
    dispatch(setC_color(c_color));
  }  

  const updateIsActive = (isActive) => {
    dispatch(setIsActive(isActive));
  }  

  const updateIsLoaded = (isLoaded) => {
    dispatch(setIsLoaded(isLoaded));
  }  

  const updateIsClosed = (isClosed) => {
    dispatch(setIsClosed(isClosed));
  }  

  const updateChatbubbles = (chatBubbles) => {
    dispatch(setChatbubbles(chatBubbles));
  }  

  const postData = {c_key:c_key};

  // title과 favicon을 동적으로 설정하는 함수
  const changeTitleAndFavicon = (c_title,href) => {
    document.title = c_title; // 페이지 타이틀 설정

    // favicon 변경
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = href; // 새 아이콘 URL로 변경
    }
  };

  //챗봇 정보 불러오는 함수
  const chatbot_res = async() => await axios.post(apiUrl+"/ajax/getMsgDatas",  postData)
      .then((response)=>{
        //console.log(response);
        const data = response.data;
        if(data.resCd === 200){
          if(!isLoaded){
            updateIsLoaded(true); //Loading 완료
            updateClKey(data.cl_key);
            updateCsQuestions(data.cs_questions.split(','));
            updateNotices(data.notices);
            updateChatbot(data.chatbot);
          }
        }else{
          alert(data.resMsg);
          updateIsClosed(true); //정상적이지 않으므로 종료
          window.history.back();
        }
      }).catch(()=>{

      });
  
  //최초 실행
  useEffect(()=>{
    chatbot_res();
  },[])

  // 이전 상태를 직접 참조하지 않고 Redux 상태를 기반으로 업데이트
useEffect(() => {
  if (isLoaded) {
      //console.log(chatbot);
      const updatedChatbot = {
          ...chatbot,
          c_icon: `${apiUrl}/${chatbot.c_icon}`, // c_icon 경로 수정
      };
      updateChatbot(updatedChatbot); // 수정된 상태를 Redux에 업데이트
      updateIsActive(chatbot.active);
      updateKeywords(chatbot.keywords.split(','));
  }

}, [isLoaded]);


  useEffect(()=>{
    if(chatbot){
      //웰컴 메시지 넣기
      updateChatbubbles([
        {
            "isUser":false,
            "message":chatbot.welcome_msg,
            "time" : moment(new Date()).format('HH:mm')
          }
      ]);
      
      //c_color 변경
      updateC_color(chatbot.c_color);

      // 컴포넌트 마운트 시 변경
      changeTitleAndFavicon("["+chatbot.c_name+"]의 챗봇", chatbot.c_icon);
      
    }

  },[chatbot])

  return (

    <ThemeProvider theme={theme}>
      <div className="App" sx={{ 
                              display: "flex", // 중앙 정렬
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              // maxWidth: "800px", // 최대 너비 제한
                              transform: "translateX(-50%)", // 정확히 중앙 배치
                              margin: "0 auto", // 화면 가운데 배치
                              height: "100vh", // 전체 화면 높이
                              backgroundColor: "#fff",
                            }}  >
          
          {/* Header(AppBar) */}
          {chatbot && 
          <>
            <Header/>
            {/* Sized Box */}
            <div className="sizedBox30" style={{height:"67px"}}/>

            {/* NoticeBar */}
            { (chatbot && isActive === 'Y') && <Noticebar/>}
          </>
          }
          
          { (chatbot && isActive === 'Y') &&  
            <Container sx={{ display:"flex", justifyContent:"center" , paddingTop:'10px'}}>
              <Paper elevation={3}
                sx={{
                p: 1.5,
                marginTop:'5px',
                maxWidth: '80%',
                minWidth: '75%',
                backgroundColor: c_color,
                opacity : "0.5",
                color: "#fff",
                borderRadius: 2}}
              >
                <Typography sx={{textAlign : 'center', fontSize:'12px', color:"#f8f8f8" }}>
                  <b>[ {chatbot.c_name} ]</b> 이(가) 대화를 시작합니다!
                </Typography> 
              </Paper>
            </Container>}
          { (chatbot && isActive === 'Y') && <ChatScreen />}
          { (chatbot && isActive === 'N') &&  
            <Container sx={{ display:"flex", justifyContent:"center" }}>
                <Paper elevation={3}
                  sx={{
                  p: 1.5,
                  marginTop:'5px',
                  maxWidth: '80%',
                  backgroundColor: c_color,
                  opacity : "0.5",
                  color: "#fff",
                  borderRadius: 2}}
                >
                  <Typography sx={{ fontSize:'12px' }}>해당 챗봇은 관리자에 의해 사용이 중지된 챗봇입니다.</Typography> 
                </Paper>
            </Container>
          }
          
          {/* Sized Box */}
          <Container  sx={{
                display:isMsging ? 'flex' : 'none',
                // display:'flex',
                flexDirection:'column',justifyContent:'center',alignItems:'center', gap:1}}
          >
              <Typography sx={{ fontSize:'12px',fontWeight:"bold",color:"#c8c8c8" }}>대답을 생각하고 있어요!</Typography>
              <CircularProgress 
                size='sm' 
                sx={{ 
                    border : `2px solid ${c_color}`,
                    borderRadius : '33%',
                    p : 1,
                    mb:2,
                    color : c_color
                 }}
               />
          </Container>
          {/* {chatbot && <BasicSpeedDial cs_questions={cs_questions}/>} */}
          <div className="sizedBox30" style={{height:"70px"}}/>                
          {/* BottomBar */}
          
          { (chatbot && isActive === 'Y' && !isClosed) &&  
        
          <BottomBar />}
      </div>
      
      {/* 로딩용 백드롭 */}
      <LoadingBackdrop/>

      {/* 종료용 백드롭 */}
      <CloseBackdrop/>

    </ThemeProvider>    
  );
}

export default App;
