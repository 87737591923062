import * as React from 'react';
import {useState,useEffect,useRef } from 'react';
import { Avatar, Box, Button, Container, Paper, Typography,Fade,Modal} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { alpha } from '@mui/system';
import { useSelector,useDispatch } from 'react-redux';
import { setChatbubbles, setIsMsging } from '../store/store';



export default function ChatScreen() {
    
    //Redux 상태 가져오기
    const chatState = useSelector((state) => state.chatState);
    const { apiUrl, chatbot, chatBubbles, cl_key, } = chatState;

    const bubblePaperStyle = { 
        // whiteSpace: 'pre-line',
        fontSize : '12px',
        '& ul': {
            mx:1,
            p:1
        },
        '& a' : {
            color : chatbot.c_color,
            textDecoration: 'underline',
        },
        '& img': {
            maxWidth: '100%', // 이미지가 부모 요소의 최대 너비를 넘지 않도록 설정
            height: 'auto', // 이미지 비율을 유지
        } ,
        '& iframe': {
            maxWidth: '100%', // 이미지가 부모 요소의 최대 너비를 넘지 않도록 설정
            height: 'auto', // 이미지 비율을 유지
        },
        '& caption':{
           fontSize:'13px',
           fontWeight:'bold',
           py:1
        },
        '& table': { // 테이블 스타일 추가
            width: '100% !important', // 테이블 너비 100%
            borderCollapse: 'collapse !important', // 테두리 겹침 방지
            my:1
        },
        '& table th': { // 테이블 헤더 스타일 추가
            width:'25% !important',
            border: `1px solid ${chatbot.c_color} !important`, // 테두리 색상
            //border: 'none !important', // 테두리 색상
            backgroundColor: `${chatbot.c_color} !important`, // 배경색
            fontSize:'11px',
            color : '#fff',
            padding: '8px !important', // 패딩 추가
            textAlign: 'center !important', // 중앙 정렬
        },
        '& table td': {
            border: '1px solid #ddd !important',
            backgroundColor: '#ffffff !important', // 배경색
            fontSize:'11px',
            color : '#777',
            padding: '8px !important', // 패딩 추가
            textAlign: 'center !important', // 중앙 정렬
        }
    }

    const c_color = chatbot.c_color;
    const c_icon = chatbot.c_icon;
    const c_name = chatbot.c_name;
    const mode = chatbot.mode;

    const dispatch = useDispatch();

    function updateChatbubbles(newChatBubbles){
        dispatch(setChatbubbles(newChatBubbles));
    }

    function updateIsMsging(newIsMsging){
        dispatch(setIsMsging(newIsMsging));
    }

    function sendMsg(user_input){
        
        //console.log(user_input);
        //console.log(user_input);
    
        var userMsgObj = {
            isUser: true,
            message: user_input,
            time : moment(new Date()).format('HH:mm')
        }
        console.log('user input ::' + userMsgObj);
        
        updateChatbubbles([...chatBubbles, userMsgObj]);
        updateIsMsging(true);
    
        // axios를 사용하여 요청하고 결과 받기
        axios.post(apiUrl+'/ajax/chat_answer_axios', {
            user_input: user_input,
            cl_key: cl_key,
            c_key: chatbot.c_key
        })
        .then(function (response) {
            console.log(response.data);
            if (response.data.resCd === 200) {
                console.log(response.data.data);
                const botMsgObj = {
                    isUser: false,
                    message: response.data.bot_msg,
                    time : moment(new Date()).format('HH:mm')
                }

                console.log('bot response :: ' + botMsgObj);

                if(response.data.simQust) botMsgObj.simQust = response.data.simQust;
                updateChatbubbles([...chatBubbles, botMsgObj]);
            } else {
                const errorBotMsgObj = {
                    isUser: false,
                    message: response.data.resMsg
                }
                updateChatbubbles([...chatBubbles, errorBotMsgObj]);
            }
        })
        .catch(function (error) {
            console.log(error);
            var botMsgObj = {
                isUser: false,
                message: "챗봇 엔진이 응답시간을 초과했습니다. 잠시 후 다시 시도해주세요!"
            }
            updateChatbubbles([...chatBubbles, botMsgObj]);
        })
        .finally(function () {
            updateIsMsging(false);
        });
    }
    
    // 채팅 박스를 스크롤하기 위한 ref 설정
    const chatEndRef = useRef(null);

    // chatBubbles 변경 시 스크롤을 가장 아래로 내리기
    useEffect(() => {
        // chatEndRef가 참조하는 요소로 스크롤을 내림
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        const links = document.querySelectorAll('.MuiTypography-root a');

        links.forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
        });

    }, [chatBubbles]); // chatBubbles가 업데이트 될 때마다 실행

    return (
        <Box 
            sx={{ p: 2 }}
        >
            {chatBubbles.map((bubble, index) => (
                <ChatBubble 
                    key={index} 
                    c_icon={c_icon} 
                    c_color={c_color} 
                    c_name = {c_name}
                    mode = {mode}
                    message={bubble.message} 
                    isUser={bubble.isUser} 
                    simQust={bubble.simQust ? bubble.simQust : []} 
                    sendMsg={sendMsg}
                    time={bubble.time}
                    bubblePaperStyle={bubblePaperStyle}></ChatBubble>
            ))}
            {/* 스크롤을 자동으로 맨 아래로 내리기 위한 빈 div */}
            <div ref={chatEndRef} />
        </Box>
    )
}

function ChatBubble({c_icon, c_color, c_name,mode, message, isUser, simQust, sendMsg, time, bubblePaperStyle }) {
    //console.log("simQust",simQust);
    const backgroundColor = (isUser) ? c_color : 'transparent';
    const fontColor = (!isUser) ? '#333' : '#fff';

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Fade in={true} timeout={800}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: isUser ? 'flex-end' : 'flex-start',
                mb: 1.5
            }}
        >   
            {/* {
                isUser === false
                ? <Avatar 
                    alt={c_name} 
                    src={c_icon} 
                    sx={{ marginRight : '5px' }}
                    onClick={handleOpen}
                    onError={(e) => {
                        console.log('이미지 로드에 실패했습니다.', e);
                        e.target.src = 'https://via.placeholder.com/150'; // 대체 이미지 URL
                    }}></Avatar>
                : null
            } */}
            
             {/* 메시지 */}
            <Box
                sx={{ 
                    maxWidth: '100%',
                 }}
            >                    
                { !isUser 
                    ? <Box sx={{ display:'flex',gap:1, justifyContent:'spance-between !important', alignItems:"center",mb:0.5  }}>
                        <Box sx={{ display:'flex',gap:1, alignItems:"center"  }}>
                            <Avatar 
                            alt={c_name} 
                            src={c_icon} 
                            sx={{ marginRight : '5px' }}
                            onClick={handleOpen}
                            onError={(e) => {
                                console.log('이미지 로드에 실패했습니다.', e);
                                e.target.src = 'https://via.placeholder.com/150'; // 대체 이미지 URL
                            }}></Avatar>      
                            <Typography component="div"  
                                sx={{
                                    color:c_color,
                                    fontSize :'12px',
                                    fontWeight :'bold',
                                }}>{c_name}  
                            </Typography>
                        </Box>
                        <Typography 
                            color={mode === 'bert' ?  'info' :  'success' } 
                            sx={{ 
                                borderRadius : '25px',
                                //backgroundColor : mode === 'bert' ? alpha("#6574CD",0.8) : alpha("#2E7D32",0.8) ,
                                border : `1px solid ${alpha(c_color,0.8)}`,
                                backgroundColor : '#fff',
                                color : c_color,
                                padding:'1px 8px',
                                fontSize :'10px'
                                }}
                        >{mode === 'bert' ?  '심플' :  'GPT' }</Typography>
                    </Box>
                    :''
                }
            {isUser && 
                <Paper
                    elevation={3}
                    sx={{
                    p: 1.5,
                    backgroundColor: backgroundColor,
                    color: fontColor,
                    borderRadius: 2,
                    textAlign:'left',
                    overflowWrap: 'break-word', // 텍스트도 넘치지 않도록 설정
                    }}
                >
                    {/* 버블 Paper */}                
                    <Typography variant="body1" component="div"
                            sx={bubblePaperStyle} 
                            dangerouslySetInnerHTML={{ __html: message }} />
                    <Container sx={{ display:'flex',
                                    flexWrap:'wrap',
                                    gap: 1,
                                    justifyContent: 'flex-start', // 버튼을 왼쪽 정렬
                                    padding: '5px 0px', // 컨테이너에 패딩 추가
                                    }}>
                        {simQust && simQust.map((question,index) => {
                            return (
                                
                            <Button
                                key={index}
                                variant="contained"
                                color="info"
                                sx={{ 
                                    //borderColor: c_color, 
                                    color : c_color,
                                    fontSize: '12px', 
                                    textAlign: 'start',
                                    backgroundColor : "#fff",
                                    padding: '4px 8px', // 버튼 안쪽 여백 추가
                                    borderRadius: '8px', // 둥근 모서리 추가
                                    minWidth: 'fit-content', // 최소 너비 설정
                                }}
                                onClick={() => sendMsg(question)}
                                >
                                {/* {question} */}
                            </Button>
                        );
                        })}
                    </Container>
            </Paper>
            }
            {!isUser && 
            <Paper
                    elevation={0}
                    sx={{
                    p: 1.5, py:0,
                    backgroundColor: backgroundColor,
                    color: fontColor,
                    borderRadius: 2,
                    textAlign:'left',
                    overflowWrap: 'break-word', // 텍스트도 넘치지 않도록 설정
                    }}
                >
                    {/* 버블 Paper */}                
                    <Typography variant="body1" component="div"
                            sx={bubblePaperStyle} 
                            dangerouslySetInnerHTML={{ __html: message }} />
                    <Container sx={{ display:'flex',
                                    flexWrap:'wrap',
                                    gap: 1,
                                    justifyContent: 'flex-start', // 버튼을 왼쪽 정렬
                                    padding: '5px 0px', // 컨테이너에 패딩 추가
                                    }}>
                        {simQust && simQust.map((question,index) => {
                            return (
                                
                            <Button
                                key={index}
                                variant="outlined"
                                color="primary"
                                sx={{ 
                                    borderColor: c_color, 
                                    fontSize: '12px', 
                                    textAlign: 'start',
                                    backgroundColor : "#fff",
                                    padding: '4px 8px', // 버튼 안쪽 여백 추가
                                    borderRadius: '8px', // 둥근 모서리 추가
                                    minWidth: 'fit-content', // 최소 너비 설정
                                }}
                                onClick={() => sendMsg(question)}
                                >
                                {question}
                            </Button>
                        );
                        })}
                    </Container>
                    {/* 채팅 시간 */}
            </Paper>
            }
            
            {/* <Typography
                variant="body2"
                sx={{
                color: !isUser ? 'gray' : '#f8f8f8',
                fontSize: '10px',
                textAlign: 'right',
                mt: 1, // 위쪽 여백 추가
                }} >
                {time}
            </Typography> */}
            

                
        </Box>                    


            {/* Modal for Enlarged Image */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >   
                <Box sx={{ outline: 'none', maxWidth: '90%', maxHeight: '90%' }}>
                    <img 
                        src={c_icon} 
                        alt="심플쇼핑몰" 
                        style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                        onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/150'; // 대체 이미지 URL
                        }}
                    />
                    
                    <Typography sx={{
                            textAlign : 'center', fontSize:'14px', color:"#f8f8f8", 
                            backgroundColor : alpha('#aaa',0.2),
                            borderRadius : 2,
                            py:1
                        }}>
                        {c_name}
                    </Typography>

                    {/* <Typography variant='body2'
                        sx={{ 
                            textAlign:'center',
                            fontSize:'14px',
                            fontWeight:'bold',
                            color : '#d3d3d3',
                            py:1
                         }}
                         onClick={handleClose}
                        > 닫기 
                    </Typography> */}
                </Box>
            </Modal>
        </Box>
        </Fade>
    );
}