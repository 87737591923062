import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useState,useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { alpha } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
// Import Swiper styles
import 'swiper/css';

export default  function Noticebar(){

    //Redux 상태 가져오기
    const chatState = useSelector((state) => state.chatState);
    const {notices, c_color} = chatState;
    
    //열려있는 아코디언 Index
    const [expandedIndex, setExpandedIndex] = useState(null);
    
    const handleAccordionChange = (index) => (event, isExpanded) => {
        setExpandedIndex(isExpanded ? index : null);
      };
    
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}

            onSlideChange={() => {
                // console.log('slide change');
                setExpandedIndex(null);
            }}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
            sx={{             
                position:'fixed',
                top:'60px',
                backgroundColor:'#fff' 
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            >
            {notices.map((notice,index)=>(
                <SwiperSlide key={index}>
                    <Accordion 
                                expanded={expandedIndex === index}
                                onChange={handleAccordionChange(index)}
                                sx={{
                                    backgroundColor: c_color ? alpha(c_color,0.05) : '#fff',
                                    // backgroundColor:chatbot.c_color,
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    margin: '0px 0',
                                    '&:before': {
                                        display: 'none', // 기본 아코디언 라인 제거
                                    },
                                }}
                          >
                        <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls={"panel"+index+"-content"}
                        id={"panel"+index+"-header"}
                        sx={{
                            padding: '0 16px',
                            backgroundColor: alpha(c_color,0.2),
                            '& .MuiAccordionSummary-content': {
                              display: 'flex',
                              alignItems: 'start',
                              margin : '0px 0'
                            },
                          }}
                        >
                        <Container sx={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                            <NotificationsActiveIcon
                            sx={{
                                color: expandedIndex === index ? '#ff7043' : '#999',
                                fontSize: '22px',
                                marginRight: '8px',
                                transition: 'transform 0.3s ease',
                                transform: expandedIndex === index ? 'scale(1.1)' : 'scale(1)',
                            }}
                            />
                            <Typography
                            variant="subtitle1"
                            sx={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: expandedIndex === index ? '#333' : '#666',
                                transition: 'color 0.3s ease',
                            }}
                            >
                            {expandedIndex === index ? notice.n_title : notice.n_title.substring(0,15)+'...'}
                            </Typography>
                        </Container>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography  sx={{padding:'0px 10px ',fontSize:'12px',
                            '& img': {
                                maxWidth: '100%', // 이미지가 부모 요소의 최대 너비를 넘지 않도록 설정
                                height: 'auto', // 이미지 비율을 유지
                            } ,
                        }} 
                            dangerouslySetInnerHTML={{ __html: notice.n_content }}>
                            {/* {notice.n_content} */}
                        </Typography>
                        </AccordionDetails>
                    </Accordion>        
                </SwiperSlide>
            ))}
        </Swiper>
    )
}