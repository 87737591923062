import * as React from 'react';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, Box, Container, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function HelpDialog(props) {

  const {c_color, open, onClose} = props;

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 1, textAlign:'center', backgroundColor:alpha(c_color,0.3), color:'#333',fontSize:'15px' }} id="customized-dialog-title">
          개발자가 알립니다!
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent dividers sx={{
           p:0,
          '& .MuiDialogContent-root' : {
            p:0
          }
        }}>
            {/* <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'start', paddingLeft:'0px'}}> */}
            <Alert severity='info'>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                본 어플리케이션은 챗봇 제작자와 방문자간의 대화를 돕기위한 목적으로 제작되었습니다.
                </Typography>
            </Alert>                
            {/* </Container> */}

            {/* <Divider sx={{maxWidth:'80%', margin:'10px auto'}}/> */}

            {/* <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'start', paddingLeft:'0px' }}> */}
            <Alert severity='info'>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                본 어플리케이션에 대한 모든 권리는 <span style={{fontWeight:'bold', color:'#6574CD'}} >SimChat</span>에 있습니다.
                </Typography>
            </Alert>

            <Divider sx={{maxWidth:'80%', margin:'5px auto'}}/>

            {/* <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'start', paddingLeft:'0px' }}> */}
            <Alert severity='warning'>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                프로그램 관련 문의는 
                <Typography variant='span'
                onClick={() => window.location.href = 'mailto:poxdkrkrkrkr@gmail.com'}
                sx={{ fontWeight: 'bold', color: '#6574CD', background: 'none', border: 'none', padding: 0 }}
                >
                poxdkrkrkrkr@gmail.com
                </Typography> 으로 부탁드립니다.
                </Typography>
              </Alert>

            <Divider sx={{maxWidth:'80%', margin:'5px auto'}}/>

            {/* <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'start', paddingLeft:'0px' }}> */}
            <Alert severity='error'>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                <u style={{fontWeight:'bold', color:'#D32f2f'}}>챗봇이 부적절한 질의응답을 포함</u>할 경우, 관리자에 의해 사용이 중지될 수 있습니다.
                </Typography>            
            </Alert>
        </DialogContent>
        <DialogActions>
          {/* <Button  color='info' autoFocus onClick={onClose}>
            확인했어요!
          </Button> */}
          <Button
                    // variant="contained"
                    color="dark"
                    size="small"
                    onClick={onClose}
                    sx={{ textTransform: 'none' }}
                >
                    닫기
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}