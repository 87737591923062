import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    RadioGroup, FormControlLabel, Button,Typography, Container,Radio,Box,
    CardContent,
    Card,
    CardHeader,
    alpha
} from '@mui/material';


function ReportModal({ c_color,modalOpen, onClose, onSubmit }) {

    const [selectedReason, setSelectedReason] = useState("기타 문제");

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(selectedReason);
        onClose();
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="report-modal-title"
            sx={{}}
        >
            <DialogTitle id="report-modal-title" sx={{  m: 0, p: 1, textAlign:'center', backgroundColor:alpha(c_color,0.3), color:'#333',fontSize:'15px'  }}>
                이 챗봇의 문제가 무엇인가요?
            </DialogTitle>

            <DialogContent dividers sx={{ p: 0.2 }}>
                <Container maxWidth="sm" sx={{ p:0 }}>
                    <Box sx={{ p: 2 }}>
                        <Card>
                            <Typography sx={{ 
                                    textAlign: 'center', mb: 1, fontSize: '12px',color:'#999',py:2,
                                    borderRadius:2,
                                    backgroundColor:alpha('#dddddd',0.4)
                                }}>
                                문제가 되는 이유를 선택해주세요.
                            </Typography>
                            
                            <CardContent
                                style={{ paddingTop : 0 }}
                            >
                                <RadioGroup
                                    value={selectedReason}
                                    onChange={handleReasonChange}
                                >
                                    {["폭력적 또는 선정적인 텍스트나 이미지", "불쾌한 텍스트 또는 이미지", "인종 또는 장애에 대한 차별, 모욕", "과도한 홍보성 또는 스팸", "기타 문제"].map((reason, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={reason}
                                            control={<Radio size='sm' />} // Radio 컴포넌트는 여기서 직접 사용됩니다.
                                            label={<Typography fontSize="13px" sx={{ color : '#333',fontSize:'12px' }}>{reason}</Typography>}
                                            sx={{ py: 1, alignItems: 'center' }}
                                        />
                                    ))}
                                    
                                </RadioGroup>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between', p: 1.5 }}>
                <Button
                    color="dark"
                    size="small"
                    onClick={onClose}
                    sx={{ textTransform: 'none' }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleSubmit}
                    sx={{ textTransform: 'none' }}
                >
                    신고
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReportModal;
